import Vue from 'vue';
import Vuex from 'vuex';
import { io } from 'socket.io-client';
import { Queue } from '../utils/queue';
import { SoundAlert } from '../utils/soundAlert';
import { extractGroupsFromProductsList } from '../utils/groups';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        usuario: {},
        printCharacteristic: null,
        comandasFilter: [],
        empresas: [],
        data: {},
        bairros: [],
        produtos: [],
        municipios: [],
        distancias: [],
        entregadores: [],
        formasEntrega: [],
        produtosGrupos: [],
        formasPagamento: [],
        configuracoes: {},
        ultimaFatura: null,
        isDashboardInitialized: false,
        socket: null,
        queueSend: new Queue(),
        isCaixaAberto: false,
        showNps: false,
        lastNps: null,
        assinatura: {},
        soundAlert: new SoundAlert(),
    },

    getters: {
        isAdmin: state => {
            return state.usuario.is_admin;
        },

        getUsuario: state => {
            return state.usuario;
        },

        getEmpresa: state => {
            return state.empresas;
        },

        getToken: state => {
            return state.usuario.token;
        },

        getData: state => {
            return state.data;
        },

        getComandasFilter: state => {
            return state.comandasFilter;
        },

        getPrintCharacteristic: state => {
            return state.printCharacteristic;
        },
    },

    mutations: {
        setUsuario(state, payload) {
            state.usuario = payload;
        },

        setEmpresas(state, payload) {
            state.empresas = payload;
        },

        setData(state, payload) {
            state.data = payload;
        },

        comandasFilter(state, payload) {
            state.comandasFilter = payload;
        },

        setPrintCharacteristic(state, payload) {
            state.printCharacteristic = payload;
        },

        setDashboardInitialized(state, payload) {
            state.isDashboardInitialized = !!payload;
        },

        setCaixaAberto(state, payload) {
            state.isCaixaAberto = !!payload;
        },

        setShowNps(state, payload) {
            state.showNps = !!payload;
        },

        setConfig(state, { option, value }) {
            state.configuracoes[option] = value;
        },

        setUltimaFatura(state, payload) {
            state.ultimaFatura = payload;
        },
    },

    actions: {
        setUpDashboardData({ state: st }, params) {
            st.bairros = params.bairros;
            st.produtos = params.produtos;
            st.distancias = params.distancias;
            st.municipios = params.municipios;
            st.entregadores = params.entregadores;
            st.formasPagamento = params.formasPgto;
            st.configuracoes = params.configuracoes;
            st.formasEntrega = params.formasEntrega;
            st.isCaixaAberto = params.caixa;
            st.showNps = params.nps;
            st.lastNps = params.lastNps;
            st.assinatura = params.assinatura;
            st.produtosGrupos = extractGroupsFromProductsList(params.produtos);

            st.isDashboardInitialized = true;
        },

        setUpWs({ state: st }) {
            const { uuid: empUuid } = st.usuario;

            const isLocalHost = document.URL.indexOf('localhost') !== -1;

            if (isLocalHost) {
                // return;
            }

            if (st.socket || !empUuid) {
                return;
            }

            st.socket = isLocalHost ? io('http://localhost:3000') : io();

            // st.socket.connected

            st.socket.on('connect', () => {
                const token = sessionStorage.getItem('token');
                token && st.socket.emit('token', token);
            });

            // st.socket.on('disconnect', () => {
                // alert('Conexão perdida, deseja recarregar a página?');
                // location.reload();
            // });

            st.socket.on('qrcode', payload => {
                document.dispatchEvent(new CustomEvent('ws-qrcode', { detail: payload }));
            });

            st.socket.on('open', () => {
                document.dispatchEvent(new Event('ws-open'));
            });

            st.socket.on('close', () => {
                document.dispatchEvent(new Event('ws-open'));
            });

            st.socket.on('order_event', payload => {
                document.dispatchEvent(new CustomEvent('ws-order-event', { detail: payload }));
            });

            st.socket.on('update_all_orders', () => {
                document.dispatchEvent(new Event('ws-update-all-orders'));
            });

            st.socket.on(`printer_${empUuid}`, payload => {
                document.dispatchEvent(new CustomEvent('ws-printer', { detail: payload }));
            });
        },

        clearDashboardData({ state: st }) {
            st.bairros = [];
            st.produtos = [];
            st.distancias = [];
            st.municipios = [];
            st.entregadores = [];
            st.configuracoes = {};
            st.formasEntrega = [];
            st.produtosGrupos = [];
            st.formasPagamento = [];

            st.isDashboardInitialized = false;
        },

        sendPrintQueue({ state: st }, params) {
            st.queueSend.push(params);
        },
    },

    modules: {
        //
    },
});
